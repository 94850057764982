const initializeSwiper = () => {
    // eslint-disable-next-line no-undef
    const swiper = new Swiper('.swiper', {
        loop: false,
        autoHeight: true,
        spaceBetween: 20,
        slidesPerView: 3,
        initialSlide: 1,

        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            480: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            640: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
    })

    // Get navigation buttons
    const nextButton = document.querySelector('.swiper-button-next')
    const prevButton = document.querySelector('.swiper-button-prev')

    // Add event listeners
    nextButton.addEventListener('click', () => swiper.slideNext())
    prevButton.addEventListener('click', () => swiper.slidePrev())
}

export default initializeSwiper
